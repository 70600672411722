<template>
  <div>
    <img style="width:100%;height:auto" :src='backgroundImg' >
    <Button style="position:absolute;top:65vh;right:30vw;background:white;border-color:#000;color:#000" @click="$router.push('/dashboard')">点击返回首页</Button>
  </div>
</template>

<script>
export default {
  name: "404",
  data(){
    return{
      backgroundImg:require('@/assets/images/404.png')
    }
  },
};
</script>

<style scoped>

</style>
